import React, { Component } from "react";
import "../App.css";
import { SectionImagePane } from "./SectionImagePane";

export class Paving extends Component {
  render() {

    var folderName = "paving"; //foldername

    return (
      <section className="services-sections">
        <h1>Paving</h1>
        <p>
          We can provide designs, paving options/styles and installation. We
          offer a range of paving options including:
        </p>
        <p>&#8226; Brick paving</p>
        <p>&#8226; Natural stone paving</p>
        <p>&#8226; Flagstone stepping pavers </p>
        <p>&#8226; Concrete pavers</p>
        <SectionImagePane imgFolderName={folderName} />
        <p>
          <a href="tel:0431077820">
            <u>Call us </u>
          </a>
          today to discuss!
        </p>
      </section>
    );
  }
}
