import React, { Component } from "react";
import "../App.css";
import homeImage from "../images/home/home1.jpg";
import secondaryImage from "../images/home/home2.jpg";

export class Home extends Component {
  render() {
    const home1ImgStyle = {
      background: `url(${homeImage}) no-repeat center`,
      backgroundSize: "cover",
      minHeight: "78vh",
      width: "100%",
      backgroundAttachment: "fixed",
      position: "relative",
    };
    const secondaryPicStyle = {
      background: `url(${secondaryImage}) no-repeat center`,
      backgroundSize: "cover",
      minHeight: "78vh",
      width: "100%",
      backgroundAttachment: "fixed",
      position: "relative",
      textAlign: "center",
    };
    return (
      <div>
        <div className="home-img" style={home1ImgStyle}>
          <div className="home-picture-content">
            <h1>DK Landscapes</h1>
            <p>
              "We provide the highest quality landscaping to Tamar Valley,
              Launceston and beyond."
            </p>
            <a className="contact-links" href="tel:0431077820">
              0431 077 820
            </a>
            <br />
            <a
              className="contact-links"
              href="mailto:dklandscapestas@gmail.com"
            >
              dklandscapestas@gmail.com
            </a>
          </div>
        </div>

        <section id="about">
          <div id="about-container">
            <h1>About Us </h1>
            <p>
              We are a small team of dedicated landscapers who pride ourselves
              on delivering customer satisfaction and timeless results. We can
              help you with all your landscaping and garden requirements.
            </p>
          </div>
        </section>

        <section className="secondary-pic" style={secondaryPicStyle}>
          <div className="secondary-picture-content">
            <p>
              "We pride ourselves in delivering customer satisfaction on all our
              jobs. We listen to our clients requirements and create landscapes
              to suit their styles."
            </p>
          </div>
        </section>
      </div>
    );
  }
}
