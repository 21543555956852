import React, { Component } from "react";
import "../App.css";
import { SectionImagePane } from "./SectionImagePane";

export class DrainageAndExcavations extends Component {
  render() {
   
    var folderName = "drainage"; //foldername

    return (
      <section className="services-sections">
        <h1>Drainage and Excavations</h1>
        <p>
          We can advise you on the best solutions to your drainage issues
          whether they be surface issues or sub-surface issues.
        </p>
        <p>
          We also can provide excavation work or clear existing gardens to
          prepare for a new facelift.
        </p>
        <SectionImagePane imgFolderName={folderName} />
        <p>
          <a href="tel:0431077820">
            <u>Call us </u>
          </a>
          today to discuss!
        </p>
      </section>
    );
  }
}
