import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./components/Home";
import { Navigation } from "./components/Navigation";
import { Lawn } from "./components/Lawn";
import { Paving } from "./components/Paving";
import { RetainingWalls } from "./components/RetainingWalls";
import { RockLandscaping } from "./components/RockLandscaping";
import { LandscapeConsultation } from "./components/LandscapeConsultation";
import { DrainageAndExcavations } from "./components/DrainageAndExcavations";
import { OutdoorGarden } from "./components/OutdoorGarden";
import { Planting } from "./components/Planting";
import { ContactUs } from "./components/ContactUs";
import { Gallery } from "./components/Gallery";
import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function App() {
  const phone = require("./images/icons/phone.png");
  const email = require("./images/icons/email.png");
  const insta = require("./images/icons/insta.png");
  const fb = require("./images/icons/fb.png");
  
  /*Function to scroll to the top after route*/
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <div className="App">
      <header className="header-contact">
        <a className="media-logo" href="tel:0431077820">
          <img src={phone} alt="phone" />
        </a>
        <a className="media-logo" href="mailto:dklandscapestas@gmail.com">
          <img src={email} alt="email" />
        </a>
        <a className="media-logo" href="https://www.facebook.com/GlengarryTas/">
          <img src={fb} alt="facebooklogo" />
        </a>
        <a className="media-logo" href="https://www.instagram.com/dklandscapestas/">
          <img src={insta} alt="instalogo" />
        </a>
      </header>

      <BrowserRouter>
        <Navigation />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Lawn" element={<Lawn />} />
          <Route path="/Paving" element={<Paving />} />
          <Route path="/RetainingWalls" element={<RetainingWalls />} />
          <Route path="/RockLandscaping" element={<RockLandscaping />} />
          <Route
            path="/LandscapeConsultation"
            element={<LandscapeConsultation />}
          />
          <Route
            path="/DrainageAndExcavations"
            element={<DrainageAndExcavations />}
          />
          <Route path="/OutdoorGarden" element={<OutdoorGarden />} />
          <Route path="/Planting" element={<Planting />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Gallery" element={<Gallery />} />
        </Routes>
      </BrowserRouter>

      <section id="contact-section">
        <div id="item-contact">
          <h2>Contact</h2>
          <p>Daniel King</p>
          <a className="contact-links" href="tel:0431077820">
            0431 077 820
          </a>
          <br />
          <a className="contact-links" href="mailto:dklandscapestas@gmail.com">
            dklandscapestas@gmail.com
          </a>
          <p>
            We are based in Tamar Valley/Launceston region in Northern Tasmania but we can
            also service other parts of the state.
          </p>
          <a className="media-logo" href="https://www.facebook.com/GlengarryTas/">
            <img src={fb} alt="facebooklogo" />
          </a>
          <a className="media-logo" href="https://www.instagram.com/dklandscapestas/">
            <img src={insta} alt="instalogo" />
          </a>
          <p>ABN: 36 542 644 982</p>
        </div>
      </section>

      <footer className="footer">
        <a href="mailto:jesse.byers01@gmail.com">&copy; Jesse Byers, 2024</a>
      </footer>
    </div>
  );
}

export default App;
