import React, { Component } from "react";
import "../App.css";
import { SectionImagePane } from "./SectionImagePane";

export class Gallery extends Component {
  render() {
    var folderName = "gallery"; //foldername

    return (
      <section className="gallery-section">
        <h1>Gallery</h1>
        <SectionImagePane imgFolderName={folderName} />
      </section>
    );
  }
}
