import React, { Component } from "react";
import "../App.css";

export class SectionImagePane extends Component {

  state = {
    apiImages: [],
    //host: "http://localhost:3000/api" UNCOMMENT FOR LOCAL
    host: "https://www.dklandscapes.com.au/api"
  };

  async componentDidMount() {
    let apiImages = [];

    //get file names
    let files;
    try {
      const response = await fetch(`${this.state.host}/${this.props.imgFolderName}`);
      files = await response.json();
      apiImages = files;
    } catch (err) {
      console.error(err)
    }
    this.setState({ apiImages });
  }

  render() {
    let imageArray = this.state.apiImages;

    //shuffle pictures
    function shuffle(array) {
      array.sort(() => Math.random() - 0.5);
    }
    shuffle(imageArray);
    
    return (
      <section className="image-pane">
        <div className="image-pane-div">
          {React.Children.toArray(
            imageArray.map((url) => (
              <img src={this.state.host + "/" + url.replace(/[0-9]/g, "").replace(".jpg","") + "/" + url} className="services-pic" alt={url} />
            ))
          )}
        </div>
      </section>

    );
  }
}
