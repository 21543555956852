import React, { Component } from "react";
import "../App.css";
import { SectionImagePane } from "./SectionImagePane";

export class Lawn extends Component {

   render() {

    var folderName = "lawn"; //foldername

    return (
      <section className="services-sections">
        <h1>Instant and Artificial Lawn</h1>

        <p>
          We provide full excavation, soil preperation, turf selection and
          installation. We use 'Strathyr<sup>&copy;</sup>' instant lawn which is
          a high quality turf grass with several varieties to choose from. We
          can also create no-fuss artifical lawn areas if required.
        </p>

        <SectionImagePane imgFolderName={folderName} />

        <p>
          <a href="tel:0431077820">
            <u>Call us </u>
          </a>
          today to discuss!
        </p>
      </section>
    );
  }
}
