import React, { Component } from "react";
import "../App.css";
import { SectionImagePane } from "./SectionImagePane";

export class Planting extends Component {
  render() {

    var folderName = "planting"; //foldername
 
    return (
      <section className="services-sections">
        <h1>Planting Selection, Design and Care</h1>
        <p>
          We can design a planting plan and supply the plants for your project.
          We can also provide you with information on how to care for your
          plants and ensure they remain healthy.
        </p>
        <SectionImagePane imgFolderName={folderName} />
        <p>
          <a href="tel:0431077820">
            <u>Call us </u>
          </a>
          today to discuss!
        </p>
      </section>
    );
  }
}
