import React, { Component } from "react";
import "../App.css";

export class ContactUs extends Component {
  render() {
    return (
      <section id="contact-us-page">
        <h1>Contact Us</h1>
        <p>Please fill the following form out and we will contact you ASAP</p>

        <form
          id="contact-us-form"
          action="https://getform.io/f/8b3c9af1-ab48-49e6-b974-eb134cccaa0a"
          method="POST"
        >
          <label>
            <span className="contact-form-required">*</span>Name:
            <input
              type="text"
              name="name"
              className="contact-form-elements"
              required
            />
          </label>

          <label>
            <span className="contact-form-required">*</span>Phone:
            <input
              type="text"
              name="phone"
              className="contact-form-elements"
              required
            />
          </label>

          <label>
            &nbsp;Email:&nbsp;
            <input
              type="email"
              name="email"
              className="contact-form-elements"
            />
          </label>

          <textarea
            rows="4"
            cols="5"
            name="query"
            id="contact-us-form-textbox"
            placeholder="How can we help you?..."
          />

          <button id="submit-button" type="submit">
            &nbsp;&nbsp;&nbsp;&nbsp;Send&nbsp;&nbsp;&nbsp;&nbsp;
          </button>
        </form>
        <p>or contact us via below</p>
      </section>
    );
  }
}
