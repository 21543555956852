import React, { Component } from "react";
import "../App.css";
import { SectionImagePane } from "./SectionImagePane";

export class OutdoorGarden extends Component {
  render() {

    var folderName = "garden"; //foldername

    return (
      <section className="services-sections">
        <h1>Garden Arbors, Decks, Pergolas and Timber Structures</h1>
        <p>
          We are able to construct these using classic timber or composite
          timber products for a more sustainable and low maintenance option.
        </p>

        <SectionImagePane imgFolderName={folderName} />

        <p>
          <a href="tel:0431077820">
            <u>Call us </u>
          </a>
          today to discuss!
        </p>
      </section>
    );
  }
}
