import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "../App.css";

export class Navigation extends Component {

  render() {
    const logo = require("../images/icons/dklandscapeslogo.png");
    /*Function to hide drop down contents after clicking */
    function hideNav() {
      var x = document.getElementById("dropdown-content");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    }

    return (
      <section id="nav-bar">
        <img src={logo} alt="Logo"></img>
        <div className="topnav">
          <NavLink className="navItem" to="/">
            Home
          </NavLink>

          <div className="dropdown">
            <button className="dropbtn">
              Services&nbsp;&nbsp;
              <i className="fa fa-caret-down"></i>
            </button>
            <div className="dropdown-content">
              <NavLink onClick={hideNav} to="/LandscapeConsultation">
                Landscape Consultation
              </NavLink>
              <NavLink onClick={hideNav} to="/Lawn">
                Lawn
              </NavLink>
              <NavLink onClick={hideNav} to="/Paving">
                Paving
              </NavLink>
              <NavLink onClick={hideNav} to="/RetainingWalls">
                Retaining Walls
              </NavLink>
              <NavLink onClick={hideNav} to="/RockLandscaping">
                Rock Landscaping
              </NavLink>
              <NavLink onClick={hideNav} to="/DrainageAndExcavations">
                Drainage And Excavations
              </NavLink>
              <NavLink onClick={hideNav} to="/OutdoorGarden">
                Garden Arbors, Decks, Pergolas and Timber Structures
              </NavLink>
              <NavLink onClick={hideNav} to="/Planting">
                Planting Selection, Design and Care
              </NavLink>
            </div>
          </div>
          <NavLink className="navItem" to="/Gallery">
            Gallery
          </NavLink>
          <NavLink className="navItem" to="/ContactUs">
            Contact
          </NavLink>
        </div>
      </section>
    );
  }
}
