import React, { Component } from "react";
import "../App.css";
import { SectionImagePane } from "./SectionImagePane";

export class LandscapeConsultation extends Component {
  render() {

    var folderName = "consult"; //foldername

    return (
      <section className="services-sections">
        <h1>Lanscape Consultation</h1>
        <p>
          We can advise you with options and help construct your dream garden.
          We have extensive knowledge in all aspects of landscape construction
          and design.
        </p>
        <SectionImagePane imgFolderName={folderName}/>
        <p>
          <a href="tel:0431077820">
            <u>Call us </u>
          </a>
          today to discuss!
        </p>
      </section>
    );
  }
}
