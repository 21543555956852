import React, { Component } from "react";
import "../App.css";
import { SectionImagePane } from "./SectionImagePane";

export class RockLandscaping extends Component {
  render() {

    var folderName = "rock"; //foldername

    return (
      <section className="services-sections">
        <h1>Rock Landscaping</h1>
        <p>
          Rocks are a great option for retaining steep banks, erosion control
          and can provide a natural looking alternative to standard concrete
          walls.
        </p>
        <p>
          We have access to a range of quarried rock which we can supply to our
          customers.
        </p>
        <SectionImagePane imgFolderName={folderName} />
        <p>
          <a href="tel:0431077820">
            <u>Call us </u>
          </a>
          today to discuss!
        </p>
      </section>
    );
  }
}
