import React, { Component } from "react";
import "../App.css";
import { SectionImagePane } from "./SectionImagePane";

export class RetainingWalls extends Component {
  render() {
    
    var folderName = "retaining"; //foldername

    return (
      <section className="services-sections">
        <h1>Retaining Walls</h1>
        <p>
          We offer a range of retaining wall options and are able to advise our
          customers on the most suitable wall to suit their needs and budget.
          Retaining wall options include:
        </p>
        <p>&#8226; Core filled reinforced retaining block walls</p>
        <p>&#8226; Garden walls</p>
        <p>&#8226; Stone and rock walls</p>
        <p>&#8226; Timber walls</p>

        <SectionImagePane imgFolderName={folderName} />
        <p>
          <a href="tel:0431077820">
            <u>Call us </u>
          </a>
          today to discuss!
        </p>
      </section>
    );
  }
}
